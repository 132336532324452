import {
  ClientDataEntity,
  GroupUnitEntity,
  RunSettings,
} from 'ssotool-app/+client/store';
import {
  Coerce,
  getLeavesFromHids,
  mapRangeLimits,
} from 'ssotool-shared/helpers';
import { checkLock } from 'ssotool-shared/modules';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  PortfolioRoadmap,
  RoadmapCampaign,
  RoadmapSite,
} from './roadmap.model';
import {
  ROADMAP_FEATURE_NAME,
  roadmapAdapter,
  RoadmapState,
} from './roadmap.reducer';

const { selectIds, selectEntities, selectAll, selectTotal } =
  roadmapAdapter.getSelectors();

const roadmapFeatureState =
  createFeatureSelector<RoadmapState>(ROADMAP_FEATURE_NAME);

const selectRoadmapEntities = createSelector(
  roadmapFeatureState,
  selectEntities,
);

export const selectRoadmapList = createSelector(roadmapFeatureState, selectAll);

export const selectLoading = createSelector(
  roadmapFeatureState,
  (state) => state.loading,
);

export const selectLoaded = createSelector(
  roadmapFeatureState,
  (state) => state.loaded,
);

export const selectRoadmap = (props: any) =>
  createSelector(selectRoadmapEntities, (entities) => {
    return entities[props?.roadmapId];
  });

export const selectRoadmapLoading = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => !!entities[props?.roadmapId]?.loading,
  );

export const selectRoadmapQueueCount = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => entities[props?.roadmapId]?.queueCount || 0,
  );

export const selectSelectedVariationIdOfRoadmap = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => entities[props?.roadmapId]?.runSettings?.variationId,
  );

export const selectRoadmapQueueFailed = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => !!entities[props?.roadmapId]?.queueFailed,
  );

export const selectRoadmapUpdating = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => !!entities[props?.roadmapId]?.updating,
  );

export const selectResultsXlsRegenerating = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => !!entities[props?.roadmapId]?.resultsRegenerating,
  );

export const selectRoadmapAccessed = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => entities[props?.roadmapId]?.accessed,
  );

export const selectCampaignLoaded = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities: Record<string, PortfolioRoadmap>) =>
      !!entities[props.roadmapId]?.campaignsLoaded,
  );

export const selectCampaignLoading = (props: any) =>
  createSelector(
    selectRoadmapEntities,
    (entities: Record<string, PortfolioRoadmap>) =>
      !!entities[props.roadmapId]?.campaignsLoading,
  );

export type RoadmapCampaignDependencies = {
  roadmapId?: string;
  campaigns: RoadmapCampaign[];
  geos: ClientDataEntity;
  geoGroups: GroupUnitEntity;
  runSettings: RunSettings;
};

const selectCampaigns = (props: RoadmapCampaignDependencies) =>
  createSelector(
    selectRoadmapEntities,
    (entities: Record<string, PortfolioRoadmap>) =>
      entities[props.roadmapId]?.campaigns || [],
  );

export const selectRoadmapCampaigns = (props: RoadmapCampaignDependencies) =>
  createSelector(
    selectCampaigns(props),
    (roadmapCampaigns: RoadmapCampaign[]) => {
      const geos = props.geos || {};
      const geoGroups = props.geoGroups || {};
      const roadmapCampaignIds = roadmapCampaigns?.map((rC) => rC.id);

      return Coerce.toArray(props.campaigns).map((cc) => {
        const roadmapCampaign = Coerce.toEmptyObject(
          roadmapCampaigns[roadmapCampaignIds.indexOf(cc.id)],
        );

        const startLimit =
          roadmapCampaign.startLimit || props.runSettings.defaultStartYear;
        const endLimit =
          roadmapCampaign.endLimit || props.runSettings.defaultEndYear;

        const hids = cc.isGeoGroup
          ? geoGroups[cc.geoId]?.geoIds?.map((geoId) => geos[geoId]?.hId)
          : [geos[cc.geoId]?.hId];

        const sites = buildSites(
          hids,
          geos,
          roadmapCampaign,
          startLimit,
          endLimit,
          cc.color,
        );

        return {
          ...cc,
          startLimit,
          endLimit,
          isSelected: roadmapCampaignIds.includes(cc.id),
          isShown: roadmapCampaignIds.includes(cc.id),
          isLock: checkLock(
            roadmapCampaign.forcedInvestments?.length,
            sites?.length,
          ),
          hids,
          sites,
          forcedInvestments: roadmapCampaign.forcedInvestments || [],
          siteConstraints: (roadmapCampaign.siteConstraints || []).map(
            (constraint) => ({
              ...constraint,
              geography: geos[constraint.geoId]?.name,
            }),
          ),
          sitePercentConstraints: (
            roadmapCampaign.sitePercentConstraints || []
          ).map((constraint) => ({
            ...constraint,
            geography: geos[constraint.geoId]?.name,
          })),
          campaignConstraints: Coerce.toEmptyObject(
            roadmapCampaign.campaignConstraints,
          ),
          campaignPercentConstraints: Coerce.toEmptyObject(
            roadmapCampaign.campaignPercentConstraints,
          ),
        } as RoadmapCampaign;
      });
    },
  );

function buildSites(
  hids: string[],
  geos: ClientDataEntity,
  rc: RoadmapCampaign,
  startLimit: string,
  endLimit: string,
  color: string,
): RoadmapSite[] {
  const siteIds = rc?.sites?.map((s) => s.id);
  const clientSites = getLeavesFromHids(geos, hids);

  return clientSites.map((site) => {
    const roadmapSite = rc?.sites?.[siteIds?.indexOf(site?.geoId)];

    return {
      ...roadmapSite,
      id: site.geoId,
      name: site.name,
      hId: site.hId,
      ...mapRangeLimits(roadmapSite, startLimit, endLimit),
      color,
      isShown: true,
      isFiltered: true,
    } as RoadmapSite;
  });
}

export const selectRoadmapTargets = ({ roadmapId }: { roadmapId: string }) =>
  createSelector(
    selectRoadmapEntities,
    (entities) => entities[roadmapId]?.targets || [],
  );

export const selectAffectedRoadmaps = ({
  roadmapIds,
}: {
  roadmapIds: string[];
}) =>
  createSelector(selectRoadmapEntities, (entities) =>
    roadmapIds.map((id) => entities[id]),
  );
