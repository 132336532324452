import * as _moment from 'moment';
import { RoadmapCampaign } from 'ssotool-app/+roadmap';
import {
  CAMPAIGN_COLORS,
  LIMIT_PARAMETER,
  REFERENCES,
} from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared/helpers';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CampaignTypes } from './campaign.model';
import {
  campaignAdapter,
  campaignFeatureKey,
  CampaignState,
} from './campaign.reducer';

const moment = _moment;

const { selectIds, selectEntities, selectAll, selectTotal } =
  campaignAdapter.getSelectors();

export const campaignFeatureState =
  createFeatureSelector<CampaignState>(campaignFeatureKey);

export const campaignListEntities = createSelector(
  campaignFeatureState,
  selectEntities,
);

export const campaignList = createSelector(campaignFeatureState, selectAll);

export const loading = createSelector(
  campaignFeatureState,
  (state) => state.loading,
);

export const fetched = createSelector(
  campaignFeatureState,
  (state) => state.fetched,
);

export const selectCampaign = createSelector(
  campaignListEntities,
  (entities, props) => entities[props?.id],
);

export const campaignLoading = createSelector(
  campaignListEntities,
  (entities, props) => entities[props?.id]?.loading,
);

export const campaignLoaded = createSelector(
  campaignListEntities,
  (entities, props) => entities[props?.id]?.loaded,
);

// custom selectors

export const list = createSelector(campaignList, (_list) => {
  return _list;
});

export const clientCampaigns = createSelector(
  campaignList,
  (campaigns: CampaignTypes[]) =>
    campaigns
      .filter((c) => !limitParameterHasNoValues(c))
      .map<RoadmapCampaign>((c) => ({
        id: c.id,
        name: c.name,
        isShown: true,
        isFiltered: true,
        color: CAMPAIGN_COLORS[REFERENCES[c.campaignType]] || '#e0e0e0',
        type: REFERENCES[c.campaignType],
        subtype: c.subtype,
        category: c.category,
        pathway: c.pathway,
        geoId: c.geoId,
        company: c.company,
        sector: c.sector,
        geography: c.geography,
        process: c.process,
        fluid: c.fluid,
        fluids: c.fluids,
        fluidIds: c.fluidIds,
        rawType: c.campaignType,
        isGeoGroup: !!c.isGeoGroup,
      })),
);

const limitParameterHasNoValues = (_d: CampaignTypes) => {
  const values = _d[LIMIT_PARAMETER[_d.campaignType]];

  return Coerce.getObjValues(values).every((_v) => Number(_v) === 0);
};
