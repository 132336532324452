import {
  BackendCampaignBaseModel,
  CampaignBaseModel,
} from 'ssotool-app/+campaign/store/campaign.model';

export class CampaignBaseMapper {
  static mapToFrontend = (
    data: BackendCampaignBaseModel,
  ): CampaignBaseModel => ({
    clientId: data?.clientId,
    id: data?.campaignId,
    campaignType: data?.campaignType,
    name: data?.name,
    owner: data?.owner,
    subtype: data?.subtype,
    category: data?.campaignCategory,
    pathway: data?.roadmap,
    renewalStrategy: data?.renewalStrategy,
    geoId: data?.geoId,
    companyId: data?.companyEntityId,
    sectorId: data?.sectorId,
    fluidId: data?.fluidId,
    processId: data?.processId,
    isGeoGroup: !!data.isGeoGroup,
    associatedFluidIds: data?.associatedFluidIds ? data.associatedFluidIds : [],
    profileSites: data?.profileSites || [],
  });

  static mapToBackend = (data: CampaignBaseModel) => ({
    campaignId: data.id,
    campaignType: data.campaignType,
    name: data.name,
    clientId: data.clientId,
    owner: data.owner,
    campaignCategory: data.category,
    subtype: data.subtype,
    roadmap: data.pathway,
    renewalStrategy: data.renewalStrategy,
    geoId: data.geoId,
    companyEntityId: data.companyId,
    sectorId: data.sectorId,
    processId: data?.processId,
    isGeoGroup: !!data.isGeoGroup,
  });
}
