import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENTITY_KEY, GEOGRAPHY_KEY } from 'ssotool-app/+client';
import {
  BaseCurveEntity,
  CurveData,
  PortfolioCurveEntities,
  PortfolioCurveEntitiesType,
  PortfolioCurveEntity,
  ProjectDetails,
  ResultFilterOptions,
} from 'ssotool-app/+roadmap/stores/result/result.model';
import { ClientFiltersService } from 'ssotool-client/services';
import {
  Coerce,
  FilterConditionValue,
  FiltersDialogConditionMode,
  FiltersDialogConfiguration,
  FilterWithCondition,
  FilterWithConditionData,
} from 'ssotool-shared';

import { Injectable } from '@angular/core';

import { DetailDataParameters } from './result-utility.model';

@Injectable()
export class ResultFiltersService extends ClientFiltersService {
  dialogConfig: FiltersDialogConfiguration = {
    conditionMode: {
      [GEOGRAPHY_KEY]: FiltersDialogConditionMode.WITH_GROUP,
      [ENTITY_KEY]: FiltersDialogConditionMode.WITH_GROUP,
    },
  };
  filterSettings = {
    keyMapper: {
      company: 'entity',
    },
  };

  filterDetailedProjects(
    clientId: string,
    projects: ProjectDetails[],
    params: DetailDataParameters,
  ): Observable<ProjectDetails[]> {
    return this.getResultFilterControlFunction<ProjectDetails>(
      clientId,
      projects,
      this.getProjectFilters(params),
    ).pipe(map(({ data }) => data));
  }

  private getProjectFilters({
    filters,
    isCampaign,
    viewLevels,
  }: DetailDataParameters) {
    return {
      ...filters,
      campaign: {
        values: isCampaign ? [viewLevels[1]] : [viewLevels[2]],
        condition: FilterConditionValue.IS,
      },
      geography: {
        values: isCampaign ? [viewLevels[2]] : [viewLevels[1]],
        condition: FilterConditionValue.IS,
      },
    };
  }

  filterDetailedCurves(
    clientId: string,
    curve: PortfolioCurveEntities,
    params: DetailDataParameters,
  ): Observable<CurveData> {
    return combineLatest(
      this.getDetailedCurveFilterControlFunction(clientId, curve, params),
    ).pipe(
      map((data) =>
        data.reduce((acc, [key, entities]) => {
          acc[key] = entities;
          return acc;
        }, {}),
      ),
    );
  }

  private getDetailedCurveFilterControlFunction(
    clientId: string,
    curve: PortfolioCurveEntities,
    { filters, requiredFields }: DetailDataParameters,
  ): Observable<[string, BaseCurveEntity[]]>[] {
    return Coerce.getObjValues(curve).reduce(
      (acc, kpiCurveData: PortfolioCurveEntitiesType) => {
        Object.entries(kpiCurveData).forEach(([kpi, kpiData]) => {
          if (requiredFields.includes(kpi)) {
            acc.push(
              this.filterPortfolioCurve(clientId, kpiData, filters).pipe(
                map(({ data }) => [kpi, data]),
              ),
            );
          }
        });
        return acc;
      },
      [],
    );
  }

  filterPortfolioCurve(
    clientId: string,
    entity: PortfolioCurveEntity,
    filters: FilterWithCondition,
  ): Observable<PortfolioCurveEntity> {
    return this.getResultFilterControlFunction<BaseCurveEntity>(
      clientId,
      entity.data,
      filters,
    ).pipe(
      map(({ data }) => ({
        data,
        unit: entity.unit,
      })),
    );
  }

  getResultFilterOptions(
    clientId: string,
    otherFilters: ResultFilterOptions,
    activeFilters: string[],
  ) {
    return this.getFilterOptions(clientId, [], this.filterSettings).pipe(
      map((clientOptions) => ({
        ...activeFilters.reduce((acc, filter) => {
          acc[filter] = otherFilters?.[filter];
          return acc;
        }, {}),
        ...clientOptions,
      })),
    );
  }

  private getResultFilterControlFunction<T>(
    clientId: string,
    data: T[],
    filters: FilterWithCondition,
  ) {
    const filterCopy = { ...filters };
    let yearFilters = filterCopy?.years;

    if (yearFilters) {
      delete filterCopy.years;
    }

    return this.getFilterControlFunction<T>(
      clientId,
      data,
      filterCopy,
      null,
      this.filterSettings,
    ).pipe(
      map(({ data, ids }) => ({
        data: yearFilters ? this.filterYears<T>(data || [], yearFilters) : data,
        ids,
      })),
    );
  }

  private filterYears<T>(data: T[], filter: FilterWithConditionData): T[] {
    return data.map((datum) => ({
      ...datum,
      values: this.getFilteredYears(datum?.['values'], filter),
    }));
  }

  private getFilteredYears(
    values: Record<string, string>,
    filter: FilterWithConditionData,
  ) {
    if (!filter.values?.length) {
      return values;
    }

    return Object.entries(values || {})
      .filter(([year, _]) => this.checkFilterCondition('', [year], filter))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }
}
