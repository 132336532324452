import { Observable, of } from 'rxjs';

import { ActivatedRoute } from '@angular/router';

export abstract class FlowBaseListComponent {
  entityType = this.route.snapshot.data['type'];
  referenceKey = this.route.snapshot.data['referenceKey'];
  ID = this.route.snapshot.params[this.referenceKey];

  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'geography', type: 'geographies' },
    { name: 'company', type: 'companies' },
    { name: 'sector', type: 'sectors' },
    { name: 'process', type: 'processes' },
    { name: 'fluid', type: 'fluids' },
    { name: 'granularity', type: 'granularity' },
    { name: 'values', type: '', mode: 'tabArea' },
  ].filter((col) => col.type !== this.entityType);
  excludedColumnFilter = ['values'];

  constructor(public route: ActivatedRoute) {}

  abstract onClick(entity: any);

  generateChartProperties(granularity: any) {
    let chartProps = {
      yearlyTabs: [],
      floor: 1,
      ceil: 365,
      minRange: 0,
      maxRange: 365,
      showRange: true,
      draggableRange: true,
    };

    /* istanbul ignore else */
    if (granularity === 'Monthly') {
      chartProps = {
        ...chartProps,
        ceil: 12,
        minRange: 0,
        maxRange: 12,
        showRange: false,
      };
    } else if (granularity === 'Yearly') {
      chartProps = {
        ...chartProps,
        minRange: 0,
        showRange: false,
      };
    }

    return chartProps;
  }
}
