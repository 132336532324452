import { CampaignFacadeService } from 'ssotool-app/+campaign/store';
import { ClientFacadeService } from 'ssotool-app/+client';
import {
  CAMPAIGN_REFERENCES_KEY,
  REFERENCES,
} from 'ssotool-app/app.references';
import {
  convertToYearlyValues,
  FormService,
  mapEnumToOptions,
} from 'ssotool-shared';

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';

import { CampaignBaseFormComponent } from '../base/campaign-base-form.component';
import { EELabelReferences } from './energy-efficiency-form.references';

@Component({
  selector: 'sso-drawer-energy-efficiency',
  templateUrl: './energy-efficiency-form.component.html',
  styleUrls: ['./energy-efficiency-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyEfficiencyFormComponent
  extends CampaignBaseFormComponent
  implements OnInit
{
  campaignType = REFERENCES.energyEfficiency;
  defaultValues = {
    origEELabel: 'E',
    finalEELabel: 'A',
    savings: convertToYearlyValues('0.0', this.startYear, this.endYear),
    incrementalSavings: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    technicalLife: convertToYearlyValues('10', this.startYear, this.endYear),
    depreciationTime: convertToYearlyValues('10', this.startYear, this.endYear),
    implementationTime: convertToYearlyValues(
      '12',
      this.startYear,
      this.endYear,
    ),
    buildCo2Scope3Emissions: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    indivisibleCo2Scope3Emissions: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    buildCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
    indivisibleCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
    incrementalBuildCost: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    incrementalIndivisibleCost: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    foM: convertToYearlyValues('0.0', this.startYear, this.endYear),
    foMPerInstall: convertToYearlyValues('0.0', this.startYear, this.endYear),
  };
  baseForm = this._formBuilder.group({
    ...this.commonControls,
    campaignType: CAMPAIGN_REFERENCES_KEY.ENERGY_EFFICIENCY,
    origEELabel: this.defaultValues.origEELabel,
    finalEELabel: this.defaultValues.finalEELabel,
    processId: ['', Validators.required],
    fluidId: '',
    metricId: ['', Validators.required],
    savings: [this.defaultValues.savings, Validators.required],
    incrementalSavings: [
      this.defaultValues.incrementalSavings,
      Validators.required,
    ],
    technicalLife: [this.defaultValues.technicalLife, Validators.required],
    depreciationTime: [
      this.defaultValues.depreciationTime,
      Validators.required,
    ],
    implementationTime: [
      this.defaultValues.implementationTime,
      Validators.required,
    ],
    buildCo2Scope3Emissions: [
      this.defaultValues.buildCo2Scope3Emissions,
      Validators.required,
    ],
    indivisibleCo2Scope3Emissions: [
      this.defaultValues.indivisibleCo2Scope3Emissions,
      Validators.required,
    ],
    buildCost: [this.defaultValues.buildCost, Validators.required],
    indivisibleCost: [this.defaultValues.indivisibleCost, Validators.required],
    incrementalBuildCost: [
      this.defaultValues.incrementalBuildCost,
      Validators.required,
    ],
    incrementalIndivisibleCost: [
      this.defaultValues.incrementalIndivisibleCost,
      Validators.required,
    ],
    foM: [this.defaultValues.foM, Validators.required],
    foMPerInstall: [this.defaultValues.foMPerInstall, Validators.required],
  });

  energyEfficiencyLabelOptions = mapEnumToOptions(EELabelReferences);

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _formBuilder: FormBuilder,
    formService: FormService,
    clientFacade: ClientFacadeService,
    campaignFacade: CampaignFacadeService,
  ) {
    super(ngControl, clientFacade, campaignFacade, formService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initValidators();
  }

  initValidators(): void {
    this.baseForm.controls.origEELabel.valueChanges
      .pipe()
      .subscribe(() => this.checkValidEELabel());
    this.baseForm.controls.finalEELabel.valueChanges
      .pipe()
      .subscribe(() => this.checkValidEELabel());
  }

  checkValidEELabel = () => {
    const _o: string = this.baseForm.controls.origEELabel.value;
    const _f: string = this.baseForm.controls.finalEELabel.value;

    if (_o.localeCompare(_f) <= 0) {
      this.baseForm.controls.origEELabel.setErrors({
        invalidEELabel: { value: _o },
      });
      this.baseForm.controls.finalEELabel.setErrors({
        invalidEELabel: { value: _f },
      });
    } else {
      this.baseForm.controls.origEELabel.setErrors(null);
      this.baseForm.controls.finalEELabel.setErrors(null);
    }
  };
}
