import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InputModule, SelectModule, YearlyInputModule } from 'ssotool-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { ExistingStorageDrawerComponent } from './existing-storage-drawer.component';

@NgModule({
  declarations: [ExistingStorageDrawerComponent],
  exports: [ExistingStorageDrawerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    InputModule,
    SelectModule,
    YearlyInputModule,
  ],
})
export class ExistingStorageDrawerModule {}
