import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { FlowBaseListComponent } from 'ssotool-app/+entities/containers/flow-base-list';
import { Coerce } from 'ssotool-app/shared/helpers';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FlowPricesBarChartDialogComponent } from './flow-prices-bar-chart-dialog';
import { FlowPricessHourlyDialogComponent } from './flow-prices-hourly-dialog';

@UntilDestroy()
@Component({
  selector: 'sso-flows-prices-list',
  templateUrl: './flow-prices-list.component.html',
  styleUrls: ['./flow-prices-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowPricesListComponent
  extends FlowBaseListComponent
  implements OnInit
{
  constructor(
    public route: ActivatedRoute,
    private clientFacade: ClientFacadeService,
    private dialog: MatDialog,
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          this.clientId = client.clientId;
          this.entities$ = this.clientFacade
            .selectFlowPrices$(client.clientId)
            .pipe(
              skipWhile((data) => !data),
              map((data) =>
                Coerce.getObjValues(data)
                  .filter(
                    (flowprices) => flowprices[this.referenceKey] === this.ID,
                  )
                  .map((flowprices) => ({
                    ...flowprices,
                    geography: flowprices.geoName,
                    company: flowprices.companyName,
                    sector: flowprices.sectorName,
                    process: flowprices.processName,
                    fluid: flowprices.fluidName,
                    granularity:
                      flowprices.granularity?.charAt(0).toUpperCase() +
                      flowprices.granularity?.slice(1),
                  })),
              ),
            );
          this.loading$ = this.clientFacade.dataLoading$(client.clientId);
        }
      });
  }

  onClick(entity: any) {
    const flowPriceTitle = entity.granularity;

    if (entity.granularity === 'Hourly') {
      this.dialog.open(FlowPricessHourlyDialogComponent, {
        width: '80%',
        data: {
          location: entity.location,
          entityId: entity.flowPriceId,
          clientId: this.clientId,
          values: entity.values,
          name: flowPriceTitle,
          fluidId: entity.fluidId,
        },
      });
    } else {
      this.dialog.open(FlowPricesBarChartDialogComponent, {
        width: '80%',
        data: {
          location: entity.location,
          entityId: entity.flowPriceId,
          clientId: this.clientId,
          values: entity.values,
          name: flowPriceTitle,
          granularity: entity.granularity,
          fluidId: entity.fluidId,
          chartProps: this.generateChartProperties(entity.granularity),
        },
      });
    }
  }
}
