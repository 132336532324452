import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService, ExistingStorage } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-shared';

import { Component, OnInit } from '@angular/core';
import { DetailsDrawerService } from '@oculus/components/details-drawer';
import {
  ExistingStorageDrawerComponent,
  ExistingStorageDrawerData,
  ExistingStorageDrawerMode,
} from 'ssotool-app/+entities/components/drawer';

const headerPrefix = 'Entities.labels.parameters';
const labelPrefix = 'Entities.labels.existingStorage';

@Component({
  selector: 'sso-existing-storage-list',
  templateUrl: './existing-storage-list.component.html',
  styleUrls: ['./existing-storage-list.component.scss'],
})
export class ExistingStorageListComponent implements OnInit {
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'geography' },
    { name: 'entity' },
    { name: 'sector' },
    { name: 'process' },
    { name: 'fluid' },
  ];

  constructor(
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade
          .selectStorages$(client.clientId)
          .pipe(
            skipWhile((data) => !data),
            map((data) =>
              Coerce.getObjValues(data).map((storage) => ({
                ...storage,
                name: storage.name,
                geography: storage.geoName,
                entity: storage.companyName,
                sector: storage.sectorName,
                fluid: storage.fluidName,
                process: storage.processName,
              })),
            ),
          );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  openDrawer(existingStorage: ExistingStorage) {
    this.drawerService.open<
      ExistingStorageDrawerComponent,
      ExistingStorageDrawerData,
      unknown
    >(ExistingStorageDrawerComponent, {
      data: {
        mode: ExistingStorageDrawerMode.VIEW,
        clientId: this.clientId,
        existingStorage,
        readonly: true,
      },
      width: '50vw',
      closeIcon: false,
      overrideClose: true,
    });
  }
}
