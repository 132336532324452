<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <sso-search-input
      class="searchInput"
      [placeholder]="'Search'"
      [label]="'Search'"
      [formControl]="searchInputControl"
    ></sso-search-input>
    <sso-filters
      [options]="filterOptions$ | async"
      [formControl]="filterControl"
      [stripLabel]="stripLabel"
      mode="dialog"
      [disable]="loading && (noData$ | async)"
      [title]="filterDialogTitle"
      [dialogConfig]="filtersDialogConfig"
    ></sso-filters>
  </div>
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="space-between"
    [ngClass]="{
      'table-column-3': COLUMN_DEFS.length === 3,
      'table-column-4': COLUMN_DEFS.length === 4
    }"
  >
    <div>
      <sso-chip-list
        [formControl]="chipForm"
        [stripLabel]="stripLabel"
      ></sso-chip-list>
    </div>
    <div
      fxFlex
      fxLayout="column"
      fxLayoutGap="10em"
      fxLayoutAlign="start center"
    >
      <mat-table
        #table
        [dataSource]="datasource$ | async"
        matSort
        [hidden]="loading"
        class="sso-stripe-table no-row-divider"
      >
        <ng-container matColumnDef="selected">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              data-testid="master-checkbox"
              color="primary"
              (change)="$event ? toggleAll() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <mat-checkbox
              data-testid="row-checkbox"
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleOne(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container
          *ngFor="let field of COLUMN_DEFS"
          [matColumnDef]="field.name"
        >
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [arrowPosition]="field.name === 'values' ? 'before' : 'after'"
          >
            {{ field.label || field.name | titlecase }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let entity; let i = index"
            [ngSwitch]="field.mode"
            [ngStyle]="{
              'justify-content': 'space-between'
            }"
            [ngClass]="{ 'bold-text': entity['boldRow'] }"
          >
            <div
              [ngClass]="{
                'value-item': field.name === 'values'
              }"
              *ngSwitchCase="'sparkline'"
            >
              <sso-yearly-input
                [formControl]="mockYearlyControl"
                [startYear]="getStartYear(entity[field.name])"
                [endYear]="getEndYear(entity[field.name])"
                [readonly]="true"
                [noControlValue]="entity[field.name]"
              ></sso-yearly-input>
            </div>
            <ng-container *ngSwitchCase="'array'">
              <ng-container *ngIf="!!entity && entity[field.name]">
                <span>{{
                  getEntityArrayFieldDisplay(entity[field.name])
                }}</span>
              </ng-container>
            </ng-container>
            <a
              data-testid="event-cell"
              class="sso-clickable"
              (click)="onClick(entity); $event.stopPropagation()"
              *ngSwitchCase="'event'"
            >
              {{ !!entity ? entity[field.name] : null }}
            </a>
            <div
              *ngSwitchCase="'tabArea'"
              class="sso-link tab-area-icon"
              (click)="onClick(entity); $event.stopPropagation()"
            >
              <mat-icon class="chart-icon" aria-hidden="false"
                >insert_chart_outlined</mat-icon
              >
            </div>

            <div
              *ngSwitchCase="'actions'"
              fxLayout="row"
              fxLayoutAlign="start start"
              fxLayoutGap="10px"
            >
              <ng-container *ngFor="let action of actions">
                <div
                  class="action-icon-container"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  *ngIf="action.shown(entity)"
                >
                  <button
                    mat-icon-button
                    (click)="action.actionFn(entity); $event.stopPropagation()"
                    type="button"
                    [color]="action.color"
                    [attr.data-testid]="
                      (action.label | translate | lowercase) + '-button'
                    "
                    [attr.title]="
                      (action.label | translate | lowercase) + '-button'
                    "
                  >
                    <mat-icon>{{ action.icon }}</mat-icon>
                  </button>
                  <span
                    class="x-small-text entity-hover"
                    [ngClass]="action.color"
                    >{{ action.label | translate | uppercase }}</span
                  >
                </div>
              </ng-container>
            </div>
            <div *ngSwitchCase="'reference'">
              {{
                !!entity
                  ? getReferenceValue(field.name, entity[field.name])
                  : null
              }}
            </div>
            <div *ngSwitchCase="'clickable-name'">
              <span
                data-testid="name-cell"
                class="sso-clickable sso-link"
                (click)="nameColumnClick(entity)"
              >
                {{ !!entity ? entity[field.name] : null }}
              </span>
            </div>
            <div *ngSwitchCase="'location'">
              ({{ !!entity ? entity[field.name].latitude : null }},
              {{ !!entity ? entity[field.name].longitude : null }})
            </div>
            <div *ngSwitchDefault>
              {{ !!entity ? entity[field.name] : null }}
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="COLUMN_DEFS_STR; sticky: true"
        ></mat-header-row>

        <mat-row
          (click)="rowClick(row)"
          *matRowDef="let row; columns: COLUMN_DEFS_STR"
        ></mat-row>
      </mat-table>
      <ng-template #loadedFooter>
        <div
          *ngIf="(dataSourceLength$ | async) === (false || null || undefined)"
          class="common-table-empty"
        >
          Nothing to display!
        </div>
      </ng-template>
    </div>

    <sso-spinner *ngIf="loading"></sso-spinner>
    <mat-paginator
      pageSize="20"
      [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
