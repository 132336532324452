import { Injectable } from '@angular/core';
import { BASELINE_CAMPAIGN_PATHWAY } from 'ssotool-app/+roadmap/roadmap.references';
import {
  ProjectDetails,
  RoadmapCampaign,
  RoadmapSite,
} from 'ssotool-app/+roadmap/stores';
import {
  CAMPAIGN_REFERENCES_KEY,
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
  FE_EXECUTION_STATUS,
} from 'ssotool-app/app.references';
import {
  checkObjectDifferences,
  createRangeArray,
  isObjectEmpty,
  isObjectValuesAllEmptyString,
  isObjectValuesAllNull,
} from 'ssotool-app/core';
import {
  Coerce,
  FilterWithCondition,
  NumYearlyValues,
  YearlyValues,
  convertToYearlyValues,
  mapRangeLimits,
} from 'ssotool-app/shared';
import { TableItem, TableItems } from 'ssotool-app/shared/modules/table-input';
import * as _ from 'lodash';
import { RunSettings } from 'ssotool-app/+client';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RoadmapTimelineService {
  defaultColumnList = createRangeArray(DEFAULT_START_YEAR, DEFAULT_END_YEAR);
  defaultColumnsNull = this.defaultColumnList.reduce((acc, curr) => {
    acc[curr] = null;
    return acc;
  }, {});

  private _enableAutosave = new BehaviorSubject<boolean>(true);
  get enableAutosave(): boolean {
    return this._enableAutosave.value;
  }
  set enableAutosave(value: boolean) {
    this._enableAutosave.next(value);
  }
  enableAutosave$ = this._enableAutosave.asObservable();

  // document
  getCampaignsWithChanges(
    values: RoadmapCampaign[],
    previousValues: RoadmapCampaign[],
    keysToCheck: string[],
  ): string[] {
    const diff = Coerce.toArray(
      checkObjectDifferences(previousValues, values, keysToCheck),
    );

    return diff.reduce((acc, id) => {
      if (!acc.includes(id.path[0])) {
        acc.push(id.path[0]);
      }
      return acc;
    }, []);
  }

  // todo: use references for strings
  // determine <20
  // determine 66-74
  determineProgressLabel(progress: number) {
    if (progress >= 20 && progress <= 65) {
      return 'resultLoading';
    } else if (progress >= 75 && progress <= 90) {
      return 'baselineIndicatorsLoading';
    } else if (progress >= 90) {
      return 'mapResults';
    }
  }

  // todo: reevaluate
  createValidatedData(
    campaigns: RoadmapCampaign[],
    fitAndAdjustLimits: boolean = true,
  ) {
    return campaigns
      .filter((item) => (item.isShown && item.isUpdated) || item.isToBeDeleted)
      .map((campaign) => ({
        ...campaign,
        sites: campaign.sites.map((site) => ({
          ...site,
          ...this.getSiteLimits(site, campaign, fitAndAdjustLimits),
        })),
      }));
  }

  private getSiteLimits(
    site: RoadmapSite,
    campaign: RoadmapCampaign,
    fitAndAdjustLimits: boolean = true,
  ) {
    if (fitAndAdjustLimits) {
      return mapRangeLimits(site, campaign.startLimit, campaign.endLimit);
    }

    return {
      startLimit: campaign.startLimit,
      endLimit: campaign.endLimit,
    };
  }

  isValidFilterChange(changes: FilterWithCondition): boolean {
    return Coerce.getObjValues(changes).reduce((isValid, sub) => {
      return isValid || !!Coerce.getObjKeys(sub).length;
    }, false);
  }

  showResultsOnlyItems(status: string): boolean {
    return [
      FE_EXECUTION_STATUS.SUCCESS,
      FE_EXECUTION_STATUS.FAILED,
      FE_EXECUTION_STATUS.CANCELLED,
      FE_EXECUTION_STATUS.OUTDATED,
    ].includes(status);
  }

  // decouple
  mapCampaignsForForm(
    campaign: RoadmapCampaign,
    roadmapCampaignIds: string[],
    results: ProjectDetails[],
    owner: string,
    roadmapId: string,
  ) {
    if (campaign.isShown) {
      roadmapCampaignIds.push(campaign.id);
    }
    const campaignResult = (results || [])
      .filter((result) => result.campaignId === campaign.id)
      .sort(
        (a, b) =>
          new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf(),
      );

    return {
      ...campaign,
      ...this.mapResultData(campaignResult),
      isResultsOnly: this.isBaselineCampaignInvested(
        (results || []).map((result) => result.campaignId),
        campaign.id,
        campaign.pathway,
      ),
      sites: campaign.sites.map((site) => ({
        ...site,
        ...this.mapResultData(
          campaignResult?.filter((project) => project?.geoId === site?.id),
        ),
      })),
      owner,
      roadmapId,
    };
  }

  private isBaselineCampaignInvested(
    projectCampaignIds: string[],
    campaignId: string,
    pathway: string,
  ): boolean {
    return (
      this.isBaselineCampaign(pathway) &&
      projectCampaignIds.includes(campaignId)
    );
  }

  private isBaselineCampaign = (pathway: string): boolean => {
    return pathway === BASELINE_CAMPAIGN_PATHWAY;
  };

  private mapResultData(results: ProjectDetails[]) {
    return results.reduce(
      (
        acc,
        { startDate, endDate, assetInstallationYear, assetExpirationYr },
      ) => {
        acc.startDates.push(startDate);
        acc.endDates.push(endDate);
        acc.installationYears.push(assetInstallationYear?.toString());
        acc.expirationYears.push(assetExpirationYr?.toString());

        return acc;
      },
      {
        startDates: [],
        endDates: [],
        installationYears: [],
        expirationYears: [],
      },
    );
  }

  convertRoadmapCampaignsConstraintsToShares(
    timelineItems: RoadmapCampaign[],
    campaignKey: string = 'campaignConstraints',
    selectedGeoIds: string[] = [],
  ) {
    return timelineItems
      .filter((campaign) => campaign.rawType === CAMPAIGN_REFERENCES_KEY.MARKET)
      .map((campaign) => ({
        id: campaign.id,
        name: campaign.name,
        values: campaign[campaignKey],
        isSelected: campaign.isSelected,
        isShown: campaign.isSelected
          ? this.isCampaignShownBasedOnSelectedGeo(campaign, selectedGeoIds)
          : campaign.isShown,
      }));
  }

  private isCampaignShownBasedOnSelectedGeo(
    campaign: RoadmapCampaign,
    selectedGeoIds: string[],
  ): boolean {
    return !!!selectedGeoIds.length || selectedGeoIds.includes(campaign.geoId);
  }

  isSharesChanged(
    currentShares: TableItems,
    previousShares: TableItems,
  ): boolean {
    return currentShares.some((currentShare, index) => {
      return (
        this.isYearlyValueChanged(currentShare, previousShares[index]) ||
        this.isSelectedValueChanged(currentShare, previousShares[index])
      );
    });
  }

  isSelectedValueChanged(
    currentShare: TableItem,
    previousShare: TableItem,
  ): boolean {
    return currentShare.isSelected !== previousShare.isSelected;
  }

  isYearlyValueChanged(
    currentShare: TableItem,
    previousShare: TableItem,
  ): boolean {
    return Object.entries(currentShare.values).some(([year, currentValue]) => {
      const previousValue = previousShare.values[year];
      return this.isValuesUnequal(previousValue, currentValue);
    });
  }

  private isValuesUnequal(item1: string, item2: string): boolean {
    return (item1 || '') !== (item2 || '');
  }

  getEdgeYearsOfShares(
    tableItem: TableItem,
    runSettings: RunSettings = {},
  ): [string, string] {
    const values = Object.entries(tableItem.values);

    if (!!runSettings && values.every(([_, val]) => !this.isValidSizing(val))) {
      return [runSettings.defaultStartYear, runSettings.defaultEndYear];
    }

    return [
      values.find(([_, value]) => this.isValidSizing(value))[0],
      _.findLast(values, ([_, value]) => this.isValidSizing(value))[0],
    ];
  }

  private isValidSizing(value: string): boolean {
    return !!value && Number(value) >= 0;
  }

  /**
   * Updates the theOtherShare according to changes and contents in
   * updatedShare, and the new limits
   * @param updatedShare     - updated siteConstraints i.e the one in view
   * @param theOtherShare    - the other siteConstraints
   * @param start            - the start year
   * @param end              - the end year
   */
  processOtherYearlyValues(
    updatedShare: YearlyValues,
    theOtherShare: YearlyValues,
    start: string,
    end: string,
    demands: NumYearlyValues,
    toPercent: boolean,
  ) {
    if (!!this.toCopyOriginalShare(updatedShare)) {
      return {
        ...updatedShare,
      };
    } else {
      return this.readjustYearlyValues(
        updatedShare,
        this.prepYearlyValues(theOtherShare),
        start,
        end,
        demands,
        toPercent,
      );
    }
  }

  /**
   * Checks if updatedShare is to be duplicated to the other constraint
   * @param updatedShare
   * @returns {boolean} true if to copy
   */
  private toCopyOriginalShare(updatedShare): boolean {
    return (
      isObjectEmpty(updatedShare) ||
      isObjectValuesAllNull(updatedShare) ||
      isObjectValuesAllEmptyString(updatedShare)
    );
  }

  /**
   * Sets default { 2010: null, ..., 2050: null } if {}
   */
  private prepYearlyValues(share: YearlyValues): YearlyValues {
    return isObjectEmpty(share)
      ? convertToYearlyValues(null, DEFAULT_START_YEAR, DEFAULT_END_YEAR)
      : share;
  }

  /**
   * Trims and/or pads the YearlyValues according to new start and end year,
   * and returns a new object (for reassignment)
   * @param values - to be modified object
   * @param start - start year
   * @param end - end
   */
  readjustYearlyValues(
    originalValues: YearlyValues,
    values: YearlyValues,
    start: string,
    end: string,
    demands: NumYearlyValues,
    toPercent: boolean,
  ): YearlyValues {
    let yearArray = createRangeArray(Number(start), Number(end));
    const paddedValues = Object.entries(values).reduce((acc, [year, value]) => {
      if (!yearArray.includes(year)) {
        acc[year] = '';
      } else {
        acc[year] = value || '0';
      }

      return acc;
    }, {});

    return this.normalizeValues(
      originalValues,
      paddedValues,
      demands,
      toPercent,
    );
  }

  normalizeValues(
    originalValues: YearlyValues,
    targetValues: YearlyValues,
    demands: NumYearlyValues,
    toPercent: boolean,
  ): YearlyValues {
    const newValues = this.clearValues(targetValues);
    Object.entries(demands).forEach(([year, maxValue]: [string, number]) => {
      const originalValue = parseFloat(originalValues[year]);
      newValues[year] = isNaN(originalValue)
        ? newValues[year]
        : this.normalize(originalValue, maxValue, toPercent, true).toString();
    });
    return newValues;
  }

  normalize(
    value: number,
    max: number,
    toPercent: boolean,
    round: boolean = false,
  ): number {
    let computed = 0;
    if (toPercent) {
      if (max === 0) {
        return 0;
      }
      computed = (value / max) * 100;
    } else {
      computed = max * (value / 100);
    }

    return toPercent
      ? parseFloat(computed.toFixed(8))
      : round
      ? Math.round(computed)
      : computed;
  }

  clearValues(values: YearlyValues): YearlyValues {
    return Object.entries(values).reduce((reduced, [key, value]) => {
      reduced[key] = isNaN(parseFloat(value)) ? value : '0';
      return reduced;
    }, {});
  }
}
