import { groupBy } from 'lodash';
import { HID_KEYS } from 'ssotool-app/+client';
import {
  ENTITY,
  GEOGRAPHY,
  YEARS,
} from 'ssotool-app/+roadmap/modules/results/analysis/curves/kpi-curve-v2/kpi-curve.references';
import { swapObjectKeyAndValue } from 'ssotool-core/utils';
import { Coerce } from 'ssotool-shared/helpers';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PortfolioEntityState } from '../portfolio.reducer';
import {
  BaseCurveEntity,
  CurveData,
  HierarchicalData,
  HierarchicalFilter,
  HierarchicalFilters,
  HierarchichalCurveData,
  PortfolioCurveEntity,
  Result,
} from './result.model';
import { RESULTS_FEATURE_NAME } from './result.reducer';

export const resultFeatureState =
  createFeatureSelector<PortfolioEntityState>(RESULTS_FEATURE_NAME);

export const progress = createSelector(
  resultFeatureState,
  (state) => state.progress,
);

export const selectResultVariationLoaded = (props: any) =>
  createSelector(
    resultFeatureState,
    (state) => state.dataMap?.[props?.roadmapId]?.[props?.variationId]?.loaded,
  );

export const selectResultVariationLoading = (props: any) =>
  createSelector(
    resultFeatureState,
    (state) => state.dataMap?.[props?.roadmapId]?.[props?.variationId]?.loading,
  );

export const selectResultVariationProgress = (props: any) =>
  createSelector(
    resultFeatureState,
    (state) =>
      state.dataMap?.[props?.roadmapId]?.[props?.variationId]?.progress,
  );

export const selectResultLoaded = (props: any) =>
  createSelector(
    resultFeatureState,
    (state) => state.dataMap?.[props?.id]?.loaded,
  );

export const selectResultLoading = (props: any) =>
  createSelector(
    resultFeatureState,
    (state) => state.dataMap?.[props?.id]?.loading,
  );

export const selectRoadmapResultData = (data: Result) => {
  return createSelector(resultFeatureState, () => data);
};

/**
 * Select the curves from the store based on the curve form and filters
 * @param data - reference roadmap data
 * @param kpiType - type of kpi
 * @param kpi - specific kpi selected
 * @param group - selected grouping for display
 * @param level - selected level for display
 * @param filters - list of selected filters
 * @param hierarchicalData - object that includes the list of hids per hierarchy
 * @param groupData - object that includes the list of hids per group
 * @returns curve entities
 */
export const selectGrouped = (
  entity: PortfolioCurveEntity,
  { splitBy, enumerateBy }: HierarchicalFilters,
  hierarchicalData: HierarchicalData,
) => {
  return createSelector(resultFeatureState, () => {
    const filteredData = entity.data;
    const displayLevels = hierarchicalData?.[splitBy.group]?.[splitBy.level];
    let splits = isYearlyRepresentation(splitBy.group)
      ? groupByYears(filteredData)
      : groupBy(filteredData, splitBy.group);

    if (displayLevels) {
      const hIdKey = HID_KEYS[splitBy.group];
      const hierarchyLevels = swapObjectKeyAndValue(displayLevels);

      splits = Coerce.getObjKeys(hierarchyLevels).reduce((acc, hId) => {
        acc[hierarchyLevels[hId]] = filteredData.filter((splits) =>
          splits[hIdKey]?.startsWith(hId),
        );

        return acc;
      }, {});
    }

    return {
      data: parseData(enumerateBy, splits, hierarchicalData),
      unit: entity?.unit,
    };
  });
};

const parseData = (
  enumerateBy: HierarchicalFilter,
  splits: CurveData,
  hierarchies?: HierarchicalData,
) => {
  if (!enumerateBy) {
    return splits;
  }

  if (isHierarchyRepresentation(enumerateBy)) {
    return enumerateByHierarchy(
      splits,
      hierarchies?.[enumerateBy.group]?.[enumerateBy.level],
      enumerateBy,
    );
  } else if (isYearlyRepresentation(enumerateBy.group)) {
    return enumerateByYears(splits);
  } else {
    return enumerateByAttribute(splits, enumerateBy.group);
  }
};

const isYearlyRepresentation = (level: string) => {
  return level === YEARS.value;
};

const isHierarchyRepresentation = (enumerateBy: HierarchicalFilter) => {
  return [GEOGRAPHY.value, ENTITY.value].includes(enumerateBy.group);
};

const groupByYears = (data: BaseCurveEntity[]): CurveData => {
  return data.reduce((grouped, datum) => {
    Object.entries(datum.values).forEach(([year, value]) => {
      if ((Number(value) || 0) > 0) {
        if (year in grouped) {
          grouped[year].push(datum);
        } else {
          grouped[year] = [datum];
        }
      }
    });
    return grouped;
  }, {});
};

const HIERARCHY_ID_MAPPING = {
  [GEOGRAPHY.value]: 'geoHid',
  [ENTITY.value]: 'companyHid',
};

const enumerateByHierarchy = (
  splits: CurveData,
  hierarchies: Record<string, string>,
  enumerateBy: HierarchicalFilter,
) => {
  return Object.entries(splits).reduce(
    (grouped, [group, campaigns]) => {
      Object.entries(hierarchies).forEach(([level, hId]) => {
        campaigns.forEach((campaign) => {
          const campaignHid = campaign[HIERARCHY_ID_MAPPING[enumerateBy.group]];
          if (campaignHid?.startsWith(hId)) {
            if (group in grouped[level]) {
              grouped[level][group].push(campaign);
            } else {
              grouped[level][group] = [campaign];
            }
          }
        });
      });
      return grouped;
    },
    Coerce.getObjKeys(hierarchies).reduce((initial, key) => {
      initial[key] = {};
      return initial;
    }, {}),
  );
};

const enumerateByAttribute = (
  splits: CurveData,
  attribute: string,
): HierarchichalCurveData => {
  return Object.entries(splits).reduce((grouped, [group, campaigns]) => {
    campaigns.forEach((campaign) => {
      const key = campaign[attribute];
      if (key in grouped) {
        if (group in grouped[key]) {
          grouped[key][group].push(campaign);
        } else {
          grouped[key][group] = [campaign];
        }
      } else {
        grouped[key] = { [group]: [campaign] };
      }
    });
    return grouped;
  }, {});
};

const enumerateByYears = (splits: CurveData) => {
  return Object.entries(splits).reduce((enumerated, [group, campaigns]) => {
    campaigns.forEach((campaign) => {
      Object.entries(campaign.values).forEach(([year, value]) => {
        const campaignData = { ...campaign, values: { year: value } };
        if (year in enumerated) {
          if (group in enumerated[year]) {
            enumerated[year][group].push(campaignData);
          } else {
            enumerated[year][group] = [campaignData];
          }
        } else {
          enumerated[year] = { [group]: [campaignData] };
        }
      });
    });
    return enumerated;
  }, {});
};

export const selectSankeyModel = (data: Result) => {
  return createSelector(
    selectRoadmapResultData(data),
    (result) => result?.sankey,
  );
};

export const selectSankeyGeographies = (data: Result) => {
  return createSelector(
    selectSankeyModel(data),
    (sankeyModel) => sankeyModel?.geographies,
  );
};

export const selectSankeyYears = (data: Result) => {
  return createSelector(
    selectSankeyModel(data),
    (sankeyModel) => sankeyModel.years,
  );
};

export const selectSankeyData = (
  data: Result,
  geography: string,
  year: string,
) => {
  return createSelector(selectSankeyModel(data), (sankeyModel) =>
    sankeyModel?.data?.[year]?.filter((datum) => datum.geography === geography),
  );
};

/**
 * Create a selector for the object of units of the different kpis.
 * @param data reference roadmap data
 * @param requiredUnits kpis needed
 * @returns unit selector
 */
export const selectUnits = (data: Result, requiredUnits: string[]) => {
  return createSelector(selectRoadmapResultData(data), (resultSet) => {
    const curve = resultSet?.['curves'];
    const kpiCollection = Coerce.getObjValues(curve).reduce(
      (kpis, kpiData) => Object.assign(kpis, kpiData),
      {},
    );

    return requiredUnits.reduce<Record<string, string>>(
      (unitReference, kpi) => {
        const unit = kpiCollection?.[kpi]?.['unit'];

        if (unit) {
          unitReference[kpi] = unit;
        }

        return unitReference;
      },
      {},
    );
  });
};
