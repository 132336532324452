import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-app/shared/helpers';
import { SSOToolRoutePathService } from 'ssotool-shared/services';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sso-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessListComponent implements OnInit {
  /** Template variables */
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'sector' },
    { name: 'input', mode: 'array' },
    { name: 'output', mode: 'array' },
  ];

  constructor(
    private clientFacade: ClientFacadeService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade
          .selectProcesses$(client.clientId)
          .pipe(
            map((data) =>
              Coerce.getObjValues(data)
                .filter(({ processId }) => processId != null)
                .map(
                  ({
                    processId,
                    name,
                    sectorName,
                    inputFluids,
                    outputFluids,
                  }) => ({
                    id: processId,
                    name,
                    sector: sectorName,
                    input: inputFluids,
                    output: outputFluids,
                  }),
                ),
            ),
          );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  onClick(event: any) {
    this.router.navigate(this.routePath.viewProcess(this.clientId, event.id));
  }
}
