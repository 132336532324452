import { CO2_EMISSIONS_STR } from 'ssotool-app/app.references';
import { FormFieldOption } from 'ssotool-shared';

import { ChartType } from './kpi-curve.models';

// KPIs
// economics
const CAPEX = { name: 'CAPEX', value: 'capex' };
const OPEX = { name: 'OPEX', value: 'opex' };
export const TDC = { name: 'Total Discounted Cost', value: 'tdc' };
const CAPEX_INVESTMENT_YEAR = {
  name: 'CAPEX Investment Year',
  value: 'capex_investment_year',
};
// environment
export const TOTAL_CO2_EMISSIONS = {
  name: 'Total CO2 Emissions',
  value: 'totalCo2Emissions',
};
export const SCOPE = { name: 'Scope', value: 'scope' };
const SCOPE_ONE = { name: 'Scope 1', value: 'scope1' };
const SCOPE_TWO = { name: 'Scope 2', value: 'scope2' };
const SCOPE_THREE = { name: 'Scope 3', value: 'scope3' };
// sustainability
export const ENERGY_CONSUMPTION = {
  name: 'Energy Consumption',
  value: 'totalEnergyConsumption',
};

export const ENERGY_PRODUCTION = {
  name: 'Energy Production',
  value: 'totalEnergyProduction',
};

export const FLUID_FLOWS = {
  name: 'Flows',
  value: 'flows',
};

export const PRIMARY_ENERGY_SUPPLY = {
  name: 'Primary Energy Supply',
  value: 'primaryEnergySupply',
};

export const FINAL_ENERGY_CONSUMPTION = {
  name: 'Final Energy Consumption',
  value: 'finalEnergyConsumption',
};

// Curves
export const STACKED_BAR = {
  name: 'Stacked Bar (Absolute)',
  value: ChartType.STACKED_BAR,
  icon: 'stacked_bar_chart',
};
export const STACKED_BAR_RELATIVE = {
  name: 'Stacked Bar (Relative)',
  value: ChartType.STACKED_BAR_RELATIVE,
  icon: 'stacked_bar_chart',
};
export const PIE = {
  name: 'Pie',
  value: ChartType.PIE,
  icon: 'pie_chart_outline',
};
const WATERFALL = {
  name: 'Waterfall',
  value: ChartType.WATERFALL,
  icon: 'waterfall_chart',
};
export const SANKEY = {
  name: 'Sankey',
  value: ChartType.SANKEY,
  icon: '',
};
export const STACKED_BARS: Array<string> = [
  ChartType.STACKED_BAR,
  ChartType.STACKED_BAR_RELATIVE,
];

// Group and Filter
export const GEOGRAPHY = { name: 'Geography', value: 'geography' };
export const YEARS = { name: 'Years', value: 'years' };
export const ENTITY = { name: 'Company', value: 'entity' };
export const CAMPAIGN = { name: 'Campaign', value: 'campaign' };
const LEVER = { name: 'Lever', value: 'lever' };
const PROCESS = { name: 'Process', value: 'process' };
const FLUID = { name: 'Fluid', value: 'fluid' };
const COMMON_FILTER = [
  YEARS.value,
  LEVER.value,
  ENTITY.value,
  PROCESS.value,
  GEOGRAPHY.value,
  CAMPAIGN.value,
  FLUID.value,
];
// economics
export const ECONOMICS_KPI_OPTIONS: FormFieldOption<string>[] = [
  CAPEX,
  OPEX,
  TDC,
  CAPEX_INVESTMENT_YEAR,
];
export const ECONOMICS_CURVE_OPTIONS: FormFieldOption<string>[] = [
  STACKED_BAR,
  STACKED_BAR_RELATIVE,
  PIE,
  WATERFALL,
];
export const ECONOMICS_GROUP_OPTIONS: FormFieldOption<string>[] = [
  YEARS,
  LEVER,
  PROCESS,
  FLUID,
  CAMPAIGN,
];
export const ECONOMICS_WITH_LEVEL_OPTIONS: FormFieldOption<string>[] = [
  GEOGRAPHY,
  ENTITY,
];
export const ECONOMICS_FILTERS: string[] = COMMON_FILTER;
// environmental
export const ENVIRONMENTAL_KPI_OPTIONS: FormFieldOption<string>[] = [
  TOTAL_CO2_EMISSIONS,
];
export const ENVIRONMENTAL_CURVE_OPTIONS: FormFieldOption<string>[] = [
  STACKED_BAR,
  STACKED_BAR_RELATIVE,
  PIE,
  WATERFALL,
];

export const ENVIRONMENTAL_GROUP_OPTIONS: FormFieldOption<string>[] = [
  YEARS,
  LEVER,
  PROCESS,
  FLUID,
  CAMPAIGN,
];
export const ENVIRONMENTAL_WITH_LEVEL_OPTIONS: FormFieldOption<string>[] = [
  GEOGRAPHY,
  ENTITY,
];
export const ENVIRONMENTAL_SUB_KPI_OPTIONS: FormFieldOption<string>[] = [
  SCOPE_ONE,
  SCOPE_TWO,
  SCOPE_THREE,
];
export const ENVIRONMENTAL_FILTERS: string[] = COMMON_FILTER;
//sustainability
export const SUSTAINABILITY_KPI_OPTIONS: FormFieldOption<string>[] = [
  ENERGY_CONSUMPTION,
  ENERGY_PRODUCTION,
  FLUID_FLOWS,
  PRIMARY_ENERGY_SUPPLY,
  FINAL_ENERGY_CONSUMPTION,
];
export const SUSTAINABILITY_CURVE_OPTIONS: FormFieldOption<string>[] = [
  STACKED_BAR,
  STACKED_BAR_RELATIVE,
  PIE,
  WATERFALL,
];
export const SUSTAINABILITY_GROUP_OPTIONS: FormFieldOption<string>[] = [
  YEARS,
  LEVER,
  PROCESS,
  FLUID,
  CAMPAIGN,
];
export const SUSTAINABILITY_WITH_LEVEL_OPTIONS: FormFieldOption<string>[] = [
  GEOGRAPHY,
  ENTITY,
];
export const SUSTAINABILITY_FILTERS: string[] = COMMON_FILTER;
// others
export const DISPLAY_GROUP_FIELD = [WATERFALL.value, PIE.value];
export const DISPLAY_LEVEL_FIELD = [GEOGRAPHY.value, ENTITY.value];
export const INCLUDE_SECONDARY_GROUPING = [TDC.value];
export const INDICATOR_TO_KPI_MAPPING = {
  capex: 'CAPEX',
  totalCo2Emissions: CO2_EMISSIONS_STR,
};

export const SANKEY_KPIS = [FLUID_FLOWS.value];
export const ZERO_VALUES_CURVE = [
  STACKED_BAR.value,
  STACKED_BAR_RELATIVE.value,
  WATERFALL.value,
];

export const RELATIVE_VALUE_UNIT = '%';
