import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  skipWhile,
  startWith,
} from 'rxjs/operators';
import {
  CampaignBaseModel,
  CampaignFacadeService,
} from 'ssotool-app/+campaign/store';
import {
  CAMPAIGN_REFERENCES_KEY,
  REFERENCES,
} from 'ssotool-app/app.references';
import {
  convertObjectToFormFieldOptions,
  DialogComponent,
} from 'ssotool-shared';

import { Component, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

import { BaseDrawerComponent } from '../base-drawer.component';
import { DrawerMode } from '../base-drawer.model';
import { CampaignDrawerData, CampaignFormModel } from './campaign-drawer.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-campaign-drawer',
  templateUrl: './campaign-drawer.component.html',
  styleUrls: ['./campaign-drawer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CampaignDrawerComponent,
      multi: true,
    },
  ],
})
export class CampaignDrawerComponent implements OnInit, ControlValueAccessor {
  @Input() set data(data: CampaignDrawerData) {
    this._data.next(data);
  }
  private _data = new BehaviorSubject<CampaignDrawerData>(null);
  private _initialData = new BehaviorSubject<CampaignDrawerData>(null);
  private _onChange = (value: CampaignFormModel) => {};
  private _onTouch = () => {};
  private _result = new BehaviorSubject<CampaignFormModel>(null);

  private _mode = new BehaviorSubject<DrawerMode>(DrawerMode.VIEW);
  private navigateToEdit = 0;

  initialData$ = this._initialData
    .asObservable()
    .pipe(filter((data) => !!data));
  campaignTypeOptions = convertObjectToFormFieldOptions(REFERENCES);
  campaignForm: FormGroup = new FormGroup({
    campaignType: new FormControl(''),
    campaignTypeForm: new FormControl(''),
  });

  loading$ = this.data?.campaign?.id
    ? this.campaignFacade.campaignLoading$(this.data?.campaign?.id)
    : this.campaignFacade.loading$;

  drawerSubscription = this.drawerService.closed$.subscribe(() => {
    this.loading$.pipe(first()).subscribe((loading) => {
      if (!loading) {
        this.onCancel();
      }
    });
  });

  mode$ = this._mode.asObservable();

  readonly$ = this.mode$.pipe(
    startWith(DrawerMode.VIEW),
    map((mode) => this.data.readonly || mode === DrawerMode.VIEW),
  );

  submit$ = new Subject();

  constructor(
    public formBuilder: FormBuilder,
    private campaignFacade: CampaignFacadeService,
    private drawerService: DetailsDrawerService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {}

  get isSubmitDisabled() {
    return this.campaignForm.controls.campaignTypeForm.pristine;
  }

  get data(): CampaignDrawerData {
    return this._data.value;
  }

  get campaignData() {
    return this._initialData.value.campaign;
  }

  get campaignTypeControl() {
    return this.campaignForm.controls.campaignType as FormControl;
  }

  ngOnInit() {
    this.campaignForm.controls.campaignType.patchValue(
      this.data.campaign?.campaignType || CAMPAIGN_REFERENCES_KEY.CONVERTER,
      { emitEvent: false },
    );
    this._mode.next(this.data.mode);

    if (this.data?.campaign && this.data.mode !== DrawerMode.CREATE) {
      this.initializeCampaign();
    }
    this.campaignForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(100))
      .subscribe((res) => {
        this._result.next(res);
        this._onChange(res);
      });
  }

  private initializeCampaign() {
    const campaignData = this.data?.campaign;
    const campaignId = campaignData?.id;
    this.campaignFacade
      .campaignLoaded$(campaignId)
      .pipe(
        first(),
        mergeMap((loaded) => {
          if (!loaded) {
            return this.campaignFacade
              .get(campaignData.clientId, campaignId, campaignData.campaignType)
              .pipe(map(() => true));
          } else {
            return of(true);
          }
        }),
        first(),
        skipWhile((hasData) => !hasData),
        mergeMap(() => this.campaignFacade.selectCampaign$(campaignId)),
        first(),
      )
      .subscribe((data) => {
        const campaign = { ...data, selectedSiteControl: '' };
        this._initialData.next({
          ...this.data,
          campaign,
        });
        this.campaignForm.controls.campaignTypeForm.patchValue(campaign, {
          emitEvent: false,
        });
        this.campaignForm.markAsPristine();
      });
  }

  modeExecutorCampaign: Record<DrawerMode, (data: CampaignBaseModel) => void> =
    {
      [DrawerMode.EDIT]: this.updateCampaign.bind(this),
      [DrawerMode.CREATE]: this.createCampaign.bind(this),
      [DrawerMode.VIEW]: () => {
        throw new Error('Cannot execute on view mode.');
      },
      [DrawerMode.EMPTY]: () => {},
    };

  onConfirm(): void {
    this.submit$.next(null);
    if (this.campaignForm.valid) {
      this.modeExecutorCampaign[this._mode.value](
        this._result.value.campaignTypeForm,
      );

      if (this._mode.value === DrawerMode.EDIT) {
        this.onClose();
      }
    }
  }

  onEdit() {
    if (this.navigateToEdit === 0) {
      this.navigateToEdit++;
    }
    this._mode.next(DrawerMode.EDIT);
    this.campaignForm.markAsPristine();
  }

  onDuplicate() {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: `Duplicate Campaign ${this.campaignData.name}`,
          message: 'Generic.messages.areYouSure',
          confirm: 'Generic.labels.duplicate',
          close: 'Generic.labels.cancel',
          disableClose: false,
          width: '250px',
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        /* istanbul ignore else */
        if (resp) {
          this.campaignFacade.batchDuplicate(this.data.clientId, {
            [this.campaignData.id]: this.campaignData.campaignType,
          });
        }
      });
  }

  onDelete() {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translateService.instant(
            'Campaign.messages.deleteCampaign',
            { name: this.campaignData.name },
          ),
          message: 'Generic.messages.areYouSure',
          confirm: 'Generic.labels.delete',
          close: 'Generic.labels.cancel',
          disableClose: false,
          width: '250px',
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        /* istanbul ignore else */
        if (resp) {
          this.onCampaignDelete();
          this.onClose();
        }
      });
  }

  private onCampaignDelete() {
    this.campaignFacade.delete(
      this.data.clientId,
      this.campaignData.id,
      this.campaignData.campaignType,
    );
    this.resetDrawer();
  }

  private resetDrawer(): void {
    this.drawerService.open(BaseDrawerComponent, {
      data: {
        mode: DrawerMode.CREATE,
        campaign: null,
        clientId: this.data.clientId,
      },
      width: '60vw',
      closeIcon: false,
      overrideClose: true,
    });
    this.drawerService.close();
  }

  onBack() {
    if (this.campaignForm.pristine) {
      this._mode.next(DrawerMode.VIEW);
      this.campaignForm.markAsPristine();
    } else {
      this.dialog
        .open(DialogComponent, {
          data: {
            title: 'Generic.labels.discardChanges',
            message: 'Generic.messages.discardChanges',
            confirm: 'Generic.labels.yes',
            close: 'Generic.labels.cancel',
            disableClose: false,
            width: '250px',
            client: {},
          },
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this._mode.next(DrawerMode.VIEW);
            this.campaignForm.markAsPristine();
          }
        });
    }
  }

  onCancel() {
    if (this.campaignForm.pristine) {
      this.onClose();
    } else {
      this.dialog
        .open(DialogComponent, {
          data: {
            title: 'Generic.labels.discardChanges',
            message: 'Generic.messages.discardChanges',
            confirm: 'Generic.labels.yes',
            close: 'Generic.labels.cancel',
            disableClose: false,
            width: '250px',
            client: {},
          },
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.onClose();
          }
        });
    }
  }

  updateCampaign(_data: CampaignBaseModel): void {
    this.campaignFacade
      .update(_data.clientId, _data.id, _data)
      .pipe(first())
      .subscribe(() => this.initializeCampaign());
  }

  createCampaign(_data: CampaignBaseModel): void {
    this.campaignFacade
      .create(this.data.clientId, _data)
      .pipe(first())
      .subscribe((campaign) =>
        this.drawerService.open(BaseDrawerComponent, {
          data: {
            mode: DrawerMode.VIEW,
            campaign,
            clientId: this.data.clientId,
          },
          width: '60vw',
          closeIcon: false,
          overrideClose: true,
        }),
      );
  }

  onClose(): void {
    this.drawerService.close();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.campaignForm.patchValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.campaignForm.disable : null;
  }
}
