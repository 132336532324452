export const READONLY_COLUMNS = [
  'name',
  'type',
  'pathway',
  'geography',
  'company',
  'sector',
];

export const FILTER_COLUMNS = ['name', 'type', 'pathway', 'sector'];

export const COLUMNS = ['select'].concat(READONLY_COLUMNS);
