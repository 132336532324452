<form
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="20px"
  data-testid="form-container"
  [formGroup]="baseForm"
  #form
>
  <div fxLayout="column" fxLayoutGap="10px">
    <!-- General Parameters -->
    <div
      fxLayout="column"
      fxLayoutGap="5px"
      data-testid="general-param-container"
      class="general-container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        data-testid="input-name-container"
      >
        <mat-icon
          fxFlex="18px"
          class="campaign-type-icon"
          data-testid="campaign-type-icon"
          [ngStyle]="{ color: COLOR[campaignType] }"
          [matTooltip]="campaignType"
        >
          circle
        </mat-icon>
        <!-- Name -->
        <sso-input
          class="full-width"
          size="large"
          data-testid="input-name-field"
          [placeholder]="'Campaign.placeholders.parameters.name' | translate"
          [label]="'Campaign.labels.parameters.name' | translate"
          [formControl]="baseForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages?.name"
          [readonly]="readonly"
          appearance="fill"
        ></sso-input>
      </div>
      <mat-divider></mat-divider>

      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- Subtype -->
        <sso-input
          data-testid="input-subtype-field"
          [placeholder]="'Campaign.placeholders.parameters.subtype' | translate"
          [label]="'Campaign.labels.parameters.subtype' | translate"
          [formControl]="baseForm?.controls?.subtype"
          [required]="true"
          [errorMessageMap]="errorMessages?.subtype"
          [readonly]="readonly"
        ></sso-input>
        <!--Campaign Category-->
        <sso-input
          data-testid="input-category-field"
          [placeholder]="
            'Campaign.placeholders.parameters.category' | translate
          "
          [label]="'Campaign.labels.parameters.category' | translate"
          [formControl]="baseForm?.controls?.category"
          [required]="true"
          [errorMessageMap]="errorMessages?.category"
          [readonly]="readonly"
        ></sso-input>
        <!-- Pathway -->
        <sso-input
          data-testid="input-roadmap-field"
          [placeholder]="'Campaign.placeholders.parameters.roadmap' | translate"
          [label]="'Campaign.labels.parameters.roadmap' | translate"
          [formControl]="baseForm?.controls?.pathway"
          [required]="true"
          [errorMessageMap]="errorMessages?.roadmap"
          [readonly]="readonly"
        ></sso-input>
        <!-- Renewal Strategy -->
        <sso-select
          data-testid="input-renewalStrategy-field"
          [label]="'Campaign.labels.parameters.renewalStrategy' | translate"
          [options]="renewalStrategyOptions"
          [control]="baseForm?.controls?.renewalStrategy"
          [errorMessageMap]="errorMessages?.renewalStrategy"
          [required]="true"
          [readonly]="readonly"
        ></sso-select>
        <!-- Pricing Method -->
        <sso-select
          data-testid="input-pricingMethod-field"
          [label]="'Campaign.labels.parameters.pricingMethod' | translate"
          [options]="pricingMethodOptions"
          [control]="baseForm?.controls?.pricingMethod"
          [errorMessageMap]="errorMessages?.pricingMethod"
          [required]="true"
          [readonly]="readonly"
        ></sso-select>
        <!-- Sizing Method -->
        <sso-select
          data-testid="input-sizingMethod-field"
          [label]="'Campaign.labels.parameters.sizingMethod' | translate"
          [options]="sizingMethodOptions"
          [control]="baseForm?.controls?.sizingMethod"
          [errorMessageMap]="errorMessages?.sizingMethod"
          [required]="true"
          [readonly]="readonly"
        ></sso-select>
      </div>
    </div>

    <!--Associated entities-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.associatedEntities' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- geo -->
        <sso-select
          data-testid="select-geography"
          [label]="'Campaign.labels.parameters.geoId' | translate"
          [options]="geoOptions$ | async"
          [control]="baseForm?.controls?.geoId"
          [errorMessageMap]="errorMessages?.geoId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Company Entity -->
        <sso-select
          data-testid="select-company"
          [label]="'Campaign.labels.parameters.companyId' | translate"
          [options]="companyOptions$ | async"
          [control]="baseForm?.controls?.companyId"
          [errorMessageMap]="errorMessages?.companyId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- sector -->
        <sso-select
          data-testid="select-sector"
          [label]="'Campaign.labels.parameters.sectorId' | translate"
          [options]="sectorOptions$ | async"
          [control]="baseForm?.controls?.sectorId"
          [errorMessageMap]="errorMessages?.sectorId"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Process -->
        <sso-select
          data-testid="select-process"
          [label]="'Campaign.labels.parameters.processId' | translate"
          [options]="processOptions$ | async"
          [control]="baseForm?.controls?.processId"
          [errorMessageMap]="errorMessages?.processId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Fluid -->
        <sso-select
          data-testid="select-fluid"
          [label]="'Campaign.labels.parameters.fluidId' | translate"
          [options]="fluidOptions$ | async"
          [control]="baseForm?.controls?.fluidId"
          [errorMessageMap]="errorMessages?.fluidId"
          [readonly]="readonly"
        >
        </sso-select>
      </div>
    </mat-expansion-panel>

    <!--Main parameters-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.main' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- Min Volume -->
        <sso-yearly-input
          data-testid="input-minVolume"
          formControlName="minVolume"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.minVolume'"
          [label]="'Campaign.labels.parameters.minVolume' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Max Volume -->
        <sso-yearly-input
          data-testid="input-maxVolume"
          formControlName="maxVolume"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.maxVolume'"
          [label]="'Campaign.labels.parameters.maxVolume' | translate"
          [warning]="
            flagWarnings(baseForm?.controls?.maxVolume?.value, startYear)
          "
          [warningMessage]="'Campaign.messages.zeroValueWarning' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Duration -->
        <sso-input
          data-testid="input-duration"
          [type]="'number'"
          [placeholder]="
            'Campaign.placeholders.parameters.duration' | translate
          "
          [label]="'Campaign.labels.parameters.duration' | translate"
          [formControl]="baseForm?.controls?.duration"
          [required]="true"
          [errorMessageMap]="errorMessages?.duration"
          [readonly]="readonly"
        ></sso-input>
      </div>
    </mat-expansion-panel>

    <!--Emission Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.emission' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Co2 Rate Scope 1 -->
        <sso-yearly-input
          data-testid="input-co2RateScope1"
          formControlName="co2RateScope1"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.co2RateScope1'"
          [label]="'Campaign.labels.parameters.co2RateScope1' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Co2 Rate Scope 2 -->
        <sso-yearly-input
          data-testid="input-co2RateScope2"
          formControlName="co2RateScope2"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.co2RateScope2'"
          [label]="'Campaign.labels.parameters.co2RateScope2' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Co2 Rate Scope 3 -->
        <sso-yearly-input
          data-testid="input-co2RateScope3"
          formControlName="co2RateScope3"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.co2RateScope3'"
          [label]="'Campaign.labels.parameters.co2RateScope3' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>

    <!--Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.cost' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Fixed Price -->
        <sso-yearly-input
          data-testid="input-fixedPrice"
          formControlName="fixedPrice"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.fixedPrice'"
          [label]="'Campaign.labels.parameters.fixedPrice' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible Cost -->
        <sso-yearly-input
          data-testid="input-indivisibleCost"
          formControlName="indivisibleCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.indivisibleCost'"
          [label]="'Campaign.labels.parameters.indivisibleCost' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!--tax-->
        <sso-yearly-input
          data-testid="input-tax"
          formControlName="tax"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.tax'"
          [label]="'Campaign.labels.parameters.tax' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
  </div>

  <button #submit type="submit" [hidden]="true"></button>
</form>
