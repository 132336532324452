<form
  fxLayout="column"
  fxLayoutGap="20px"
  [formGroup]="baseForm"
  #form
  data-testid="form-container"
>
  <div fxLayout="column" fxLayoutGap="10px">
    <!-- General Parameters -->
    <div class="general-container" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon
          fxFlex="18px"
          data-testid="campaign-type-icon"
          class="campaign-type-icon"
          [ngStyle]="{ color: COLOR[campaignType] }"
          [matTooltip]="campaignType"
        >
          circle
        </mat-icon>
        <sso-input
          data-testid="input-name-field"
          appearance="fill"
          size="large"
          [placeholder]="'Campaign.placeholders.parameters.name' | translate"
          [label]="'Campaign.labels.parameters.name' | translate"
          [formControl]="baseForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages?.name"
          [readonly]="readonly"
        ></sso-input>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- SubType -->
        <sso-input
          data-testid="input-subtype-field"
          [placeholder]="'Campaign.placeholders.parameters.subtype' | translate"
          [label]="'Campaign.labels.parameters.subtype' | translate"
          [formControl]="baseForm?.controls?.subtype"
          [required]="true"
          [errorMessageMap]="errorMessages?.subtype"
          [readonly]="readonly"
        ></sso-input>
        <!-- Scale -->
        <sso-input
          data-testid="input-scale-field"
          [placeholder]="'Campaign.placeholders.parameters.scale' | translate"
          [label]="'Campaign.labels.parameters.scale' | translate"
          [formControl]="baseForm?.controls?.scale"
          [required]="true"
          [errorMessageMap]="errorMessages?.scale"
          [readonly]="readonly"
        ></sso-input>
        <!-- Category -->
        <sso-input
          data-testid="input-category-field"
          [placeholder]="
            'Campaign.placeholders.parameters.category' | translate
          "
          [label]="'Campaign.labels.parameters.category' | translate"
          [formControl]="baseForm?.controls?.category"
          [required]="true"
          [errorMessageMap]="errorMessages?.category"
          [readonly]="readonly"
        ></sso-input>
        <!-- Roadmap -->
        <sso-input
          data-testid="input-roadmap-field"
          [placeholder]="'Campaign.placeholders.parameters.roadmap' | translate"
          [label]="'Campaign.labels.parameters.roadmap' | translate"
          [formControl]="baseForm?.controls?.pathway"
          [required]="true"
          [errorMessageMap]="errorMessages?.roadmap"
          [readonly]="readonly"
        ></sso-input>
        <!-- Renewal Strategy -->
        <sso-select
          data-testid="select-renewal-strategy-field"
          [label]="'Campaign.labels.parameters.renewalStrategy' | translate"
          [options]="renewalStrategyOptions"
          [control]="baseForm?.controls?.renewalStrategy"
          [errorMessageMap]="errorMessages?.renewalStrategy"
          [required]="true"
          [readonly]="readonly"
        ></sso-select>
      </div>
    </div>
    <!-- Associated Entities -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.associatedEntities' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Geography -->
        <sso-select
          data-testid="select-geo-field"
          [label]="'Campaign.labels.parameters.geoId' | translate"
          [options]="geoOptions$ | async"
          [control]="baseForm?.controls?.geoId"
          [errorMessageMap]="errorMessages?.geoId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Company Entity -->
        <sso-select
          data-testid="select-company-field"
          [label]="'Campaign.labels.parameters.companyId' | translate"
          [options]="companyOptions$ | async"
          [control]="baseForm?.controls?.companyId"
          [errorMessageMap]="errorMessages?.companyId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Sector -->
        <sso-select
          data-testid="select-sector-field"
          [label]="'Campaign.labels.parameters.sectorId' | translate"
          [options]="sectorOptions$ | async"
          [control]="baseForm?.controls?.sectorId"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Process -->
        <sso-select
          data-testid="select-process-field"
          [label]="'Campaign.labels.parameters.processId' | translate"
          [options]="processOptions$ | async"
          [control]="baseForm?.controls?.processId"
          [errorMessageMap]="errorMessages?.processId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
      </div>
    </mat-expansion-panel>
    <!-- Main Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.main' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Min Capacity -->
        <sso-yearly-input
          data-testid="yearly-min-capacity-field"
          formControlName="minCapacity"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.minCapacity'"
          [label]="'Campaign.labels.parameters.minCapacity' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Max Capacity -->
        <sso-yearly-input
          data-testid="yearly-max-capacity-field"
          formControlName="maxCapacity"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.maxCapacity'"
          [label]="'Campaign.labels.parameters.maxCapacity' | translate"
          [readonly]="readonly"
          [warning]="
            flagWarnings(baseForm?.controls?.maxCapacity?.value, startYear)
          "
          [warningMessage]="'Campaign.messages.zeroValueWarning' | translate"
        ></sso-yearly-input>
        <!-- Minimum Operating hours -->
        <sso-yearly-input
          data-testid="yearly-min-operating-hours-field"
          formControlName="minimumOperatingHours"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.minimumOperatingHours'"
          [label]="
            'Campaign.labels.parameters.minimumOperatingHours' | translate
          "
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
        <!-- Technical life -->
        <sso-yearly-input
          data-testid="yearly-technical-life-field"
          formControlName="technicalLife"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.technicalLife'"
          [label]="'Campaign.labels.parameters.technicalLife' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
        <!-- Implementation time -->
        <sso-yearly-input
          data-testid="yearly-implementation-time-field"
          formControlName="implementationTime"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.implementationTime'"
          [label]="'Campaign.labels.parameters.implementationTime' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
        <!-- Depreciation time -->
        <sso-yearly-input
          data-testid="yearly-depreciation-time-field"
          formControlName="depreciationTime"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.depreciationTime'"
          [label]="'Campaign.labels.parameters.depreciationTime' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>

        <div
          [fxLayout]="readonly ? 'row' : 'column'"
          fxLayoutGap="20px"
          class="effiency-matrix-container sso-spacer-top-half"
          fxFlex="100%"
        >
          <div fxLayout="column">
            <p>
              {{ 'Campaign.labels.parameters.consumptionFluids' | translate }}
            </p>
            <sso-fluid-array
              data-testid="fluid-consumption-field"
              [viewMode]="data?.mode"
              formControlName="consumptionFluids"
              [fluidOptions]="fluidOptions$ | async"
              [startYear]="baseForm?.controls?.startYear?.value"
              [endYear]="baseForm?.controls?.endYear?.value"
              [mainInput]="baseForm?.controls?.mainConsumptionFluidId?.value"
              (consumption)="setMainConsumption($event)"
              label="Specific Consumption"
              [viewMode]="readonly && 'view'"
            >
            </sso-fluid-array>
            <mat-error
              *ngFor="
                let error of getErrors(
                  baseForm?.controls?.consumptionFluids?.errors
                )
              "
              [innerHtml]="
                'Campaign.messages.errors.specificConsumption.required'
                  | translate
              "
            >
            </mat-error>
            <mat-error
              *ngFor="
                let error of getErrors(
                  baseForm?.controls?.mainConsumptionFluidId?.errors
                )
              "
              [innerHtml]="
                'Campaign.messages.errors.specificConsumption.mainRequired'
                  | translate
              "
            >
            </mat-error>
          </div>
          <div fxLayout="column">
            <p>
              {{ 'Campaign.labels.parameters.productionFluids' | translate }}
            </p>
            <sso-fluid-array
              data-testid="fluid-production-field"
              [viewMode]="data?.mode"
              formControlName="productionFluids"
              [fluidOptions]="fluidOptions$ | async"
              [startYear]="baseForm?.controls?.startYear?.value"
              [endYear]="baseForm?.controls?.endYear?.value"
              [mainInput]="baseForm?.controls?.mainProductionFluidId?.value"
              (consumption)="setMainProduction($event)"
              label="Specific Production"
              [viewMode]="readonly && 'view'"
            >
            </sso-fluid-array>
            <mat-error
              *ngFor="
                let error of getErrors(
                  baseForm?.controls?.productionFluids?.errors
                )
              "
              [innerHtml]="
                'Campaign.messages.errors.specificProduction.required'
                  | translate
              "
            >
            </mat-error>
            <mat-error
              *ngFor="
                let error of getErrors(
                  baseForm?.controls?.mainProductionFluidId?.errors
                )
              "
              [innerHtml]="
                'Campaign.messages.errors.specificProduction.mainRequired'
                  | translate
              "
            >
            </mat-error>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <!-- Emission Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.emission' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Build CO2 Scope 3 Emissions -->
        <sso-yearly-input
          data-testid="yearly-build-emissions-field"
          formControlName="buildCo2Scope3Emissions"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCo2Scope3Emissions'"
          [label]="
            'Campaign.labels.parameters.buildCo2Scope3Emissions' | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible CO2 Scope 3 Emissions -->
        <sso-yearly-input
          data-testid="yearly-indivisible-emissions-field"
          formControlName="indivisibleCo2Scope3Emissions"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="
            'Campaign.messages.errors.indivisibleCo2Scope3Emissions'
          "
          [label]="
            'Campaign.labels.parameters.indivisibleCo2Scope3Emissions'
              | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
    <!-- Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.cost' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Build cost -->
        <sso-yearly-input
          data-testid="yearly-build-cost-field"
          formControlName="buildCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCost'"
          [label]="'Campaign.labels.parameters.buildCost' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible cost -->
        <sso-yearly-input
          data-testid="yearly-indivisible-cost-field"
          formControlName="indivisibleCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.indivisibleCost'"
          [label]="'Campaign.labels.parameters.indivisibleCost' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M -->
        <sso-yearly-input
          data-testid="yearly-fom-field"
          formControlName="foM"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foM'"
          [label]="'Campaign.labels.parameters.foM' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M per install -->
        <sso-yearly-input
          data-testid="yearly-fom-install-field"
          formControlName="foMPerInstall"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foMPerInstall'"
          [label]="'Campaign.labels.parameters.foMPerInstall' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
  </div>
  <button #submit type="submit" [hidden]="true"></button>
</form>
