import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnalysisMapper } from 'ssotool-app/+roadmap/services';
import {
  FE_EXECUTION_STATUS,
  REFERENCES,
  CAMPAIGNS_WITH_CAMPAIGN_LEVEL_CONSTRAINTS,
  CAMPAIGN_REFERENCES_KEY,
} from 'ssotool-app/app.references';
import { generateEndpoint } from 'ssotool-core/utils';
import { Coerce, ExecStatusChecker, YearlyValuesMapper } from 'ssotool-shared';
import { getDateDiff } from 'ssotool-shared/modules/gantt/gantt.references';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { UpdateRunSettingsParams } from './roadmap-parameters.model';
import {
  BackendPortfolioRoadmap,
  BackendRoadmapCampaign,
  BackendRoadmapSite,
  BackendRoadmapSiteConstraints,
  BackendRoadmapsRun,
  BackendRoadmapTarget,
  PortfolioRoadmap,
  RoadmapCampaign,
  RoadmapParameters,
  RoadmapRunMap,
  RoadmapSite,
  RoadmapSiteConstraints,
  RoadmapsRun,
  RoadmapTarget,
} from './roadmap.model';
import { TimelineType } from 'ssotool-app/+roadmap/modules/timeline/roadmap-timeline.model';

@Injectable()
export class RoadmapApiService {
  // API CALLS
  getRoadmap(
    clientId: string,
    roadmapId: string,
  ): Observable<PortfolioRoadmap> {
    return this.httpClient.get(this.makeGetEndpoint(clientId, roadmapId)).pipe(
      map((response: BackendPortfolioRoadmap) => this.mapToFrontend(response)),
      catchError((error) => throwError(error)),
    );
  }

  getAllRoadmaps(clientId: string): Observable<PortfolioRoadmap[]> {
    return this.httpClient.get(this.makeGetAllEndpoint(clientId)).pipe(
      map((response: BackendPortfolioRoadmap[]) =>
        response.map((data) => this.mapToFrontend(data)),
      ),
      catchError((error) => throwError(error)),
    );
  }

  createRoadmap({
    clientId,
    data,
    generateInputs,
    name,
    targets,
    timelineType,
  }: RoadmapParameters): Observable<PortfolioRoadmap> {
    return this.httpClient
      .post(
        this.makeCreateEndpoint(clientId),
        this.mapToBackend(data, targets, {
          clientId,
          generateInputs,
          name,
          timelineType,
        }),
      )
      .pipe(
        map((response: BackendPortfolioRoadmap) =>
          this.mapToFrontend(response),
        ),
        catchError((error) => throwError(error)),
      );
  }

  updateRoadmap({
    clientId,
    roadmapId,
    owner,
    data,
    generateInputs,
    name,
    isBaseline,
    isNameUpdate,
    targets,
    timelineType = 'gantt',
  }: RoadmapParameters): Observable<PortfolioRoadmap> {
    return this.httpClient
      .post(
        this.makeUpdateEndpoint(clientId, roadmapId),
        this.mapToBackend(
          data,
          targets,
          {
            clientId,
            roadmapId,
            owner,
            generateInputs,
            name,
            timelineType,
          },
          true,
        ),
        {
          params: {
            ...(isBaseline && { isBaseline: '' }),
            ...(isNameUpdate && { isNameUpdate: '' }),
          },
        },
      )
      .pipe(
        map((response: BackendPortfolioRoadmap) =>
          this.mapToFrontend(response),
        ),
        catchError((error) => throwError(error)),
      );
  }

  deleteRoadmap({ clientId, roadmapId }: RoadmapParameters): Observable<null> {
    return this.httpClient
      .delete(this.makeDeleteEndpoint(clientId, roadmapId))
      .pipe(
        map(() => null),
        catchError((error) => throwError(error)),
      );
  }

  computeRoadmap({
    clientId,
    roadmapId,
    owner,
    data,
    name,
    isTriggerMeteo,
    targets,
  }: RoadmapParameters): Observable<PortfolioRoadmap> {
    return this.httpClient
      .post(this.makeComputeEndpoint(clientId, roadmapId), {
        ...this.mapToBackend(data, targets, {
          clientId,
          roadmapId,
          owner,
          generateInputs: false,
          name,
        }),
        proceed: isTriggerMeteo,
      })
      .pipe(
        map((response: BackendPortfolioRoadmap) =>
          this.mapToFrontend(response),
        ),
        catchError((error) => throwError(error)),
      );
  }

  stopComputeRoadmap(clientId, roadmapId) {
    return this.httpClient
      .get(this.makeStopComputeEndpoint(clientId, roadmapId))
      .pipe(
        map((response: BackendPortfolioRoadmap) => response),
        catchError((error) => throwError(error)),
      );
  }

  computeMultipleRoadmap(
    clientId,
    roadmapIds: string[],
    isTriggerMeteo?: boolean,
  ): Observable<RoadmapsRun> {
    return this.httpClient
      .post(this.makeComputeMultipleEndpoint(clientId), {
        roadmapIds,
        proceed: isTriggerMeteo,
      })
      .pipe(
        map((response: BackendRoadmapsRun) =>
          this.mapComputeMultipleToFrontend(response),
        ),
        catchError((error) => throwError(error)),
      );
  }

  duplicateRoadmaps(
    clientId: string,
    roadmapIds: string[],
  ): Observable<PortfolioRoadmap[]> {
    return this.httpClient
      .post(this.makeDuplicateRoadmapsEndpoint(clientId), {
        roadmapIds,
      })
      .pipe(
        map((response: BackendPortfolioRoadmap[]) =>
          response.map((data) => this.mapToFrontend(data)),
        ),
        catchError((error) => throwError(error)),
      );
  }

  updateRunSettings({
    clientId,
    roadmapId,
    settings,
  }: UpdateRunSettingsParams) {
    return this.httpClient
      .post(
        this.makeUpdateEndpoint(clientId, roadmapId),
        { ...settings },
        { params: { isRunSettings: '' } },
      )
      .pipe(
        map((data: BackendPortfolioRoadmap) => this.mapToFrontend(data)),
        catchError((error) => throwError(error)),
      );
  }

  fetchRoadmapCampaigns(
    clientId: string,
    roadmapId: string,
  ): Observable<RoadmapCampaign[]> {
    return this.httpClient
      .get(this.makeFetchRoadmapCampaignsEndpoint(clientId, roadmapId))
      .pipe(
        map((data: BackendRoadmapCampaign[]) =>
          this.mapCampaignsToFrontend(data, roadmapId),
        ),
        catchError((error) => throwError(error)),
      );
  }

  updateRoadmapCampaigns(
    clientId: string,
    roadmapId: string,
    campaigns: Array<RoadmapCampaign>,
  ) {
    return this.httpClient
      .put(
        this.makeUpdateRoadmapCampaignsEndpoint(clientId, roadmapId),
        this.mapCampaignsToBackend(campaigns),
        {
          params: {
            mode: 'update',
          },
        },
      )
      .pipe(
        map((data: BackendPortfolioRoadmap) => this.mapToFrontend(data)),
        catchError((error) => throwError(error)),
      );
  }

  deleteRoadmapCampaigns(
    clientId: string,
    roadmapId: string,
    campaigns: Array<RoadmapCampaign>,
  ) {
    return this.httpClient
      .put(
        this.makeDeleteRoadmapCampaignsEndpoint(clientId, roadmapId),
        campaigns,
        {
          params: {
            mode: 'delete',
          },
        },
      )
      .pipe(catchError((error) => throwError(error)));
  }

  // MODEL MAPPERS
  mapToBackend(
    data: RoadmapCampaign[],
    targets: RoadmapTarget[],
    {
      clientId,
      roadmapId = '',
      owner = '',
      generateInputs = false,
      name,
      timelineType = 'gantt' as TimelineType,
    },
    partialUpdate: boolean = false,
  ): BackendPortfolioRoadmap {
    if (partialUpdate) {
      return {
        targets: this.mapTargetsToBackend(targets) || [],
        clientId,
        roadmapId,
        owner,
        generateInputs,
        name,
        timelineType,
      };
    }

    return {
      campaigns: this.mapCampaignsToBackend(data),
      targets: this.mapTargetsToBackend(targets) || [],
      clientId,
      roadmapId,
      owner,
      generateInputs,
      name,
      timelineType,
    };
  }

  mapCampaignsToBackend(
    items: RoadmapCampaign[] = [],
  ): BackendRoadmapCampaign[] {
    return items.map(
      (item) =>
        ({
          startDate: this.getYear(item.startLimit),
          duration: getDateDiff(item.endLimit, item.startLimit) || 0,
          campaignId: item.id,
          campaignType: Coerce.getObjKeys(REFERENCES).find(
            (key) => REFERENCES[key] === item.type,
          ),
          sites: this.mapSiteItemsToBackend(item.sites),
          siteConstraints: this.mapSiteConstraintsToBackend(
            item.siteConstraints,
          ),
          sitePercentConstraints: this.mapSiteConstraintsToBackend(
            item.sitePercentConstraints,
          ),
          campaignConstraints: this.isCampaignConstraintsAllowed(item.rawType)
            ? YearlyValuesMapper.mapToBackend(item.campaignConstraints, true)
            : [],
          campaignPercentConstraints: this.isCampaignConstraintsAllowed(
            item.rawType,
          )
            ? YearlyValuesMapper.mapToBackend(
                item.campaignPercentConstraints,
                true,
              )
            : [],
          forcedInvestments: item.forcedInvestments,
          owner: item.owner,
          roadmapId: item.roadmapId,
        } as BackendRoadmapCampaign),
    );
  }

  isCampaignConstraintsAllowed(rawType: string): boolean {
    return CAMPAIGNS_WITH_CAMPAIGN_LEVEL_CONSTRAINTS.includes(
      rawType as CAMPAIGN_REFERENCES_KEY,
    );
  }

  mapSiteItemsToBackend(sites: RoadmapSite[] = []): BackendRoadmapSite[] {
    return sites
      .filter((site) => site.startLimit && site.endLimit)
      .map((site) => ({
        startDate: this.getYear(site.startLimit),
        duration: getDateDiff(site.endLimit, site.startLimit) || 0,
        geoId: site.id,
      }));
  }

  mapSiteConstraintsToBackend(
    siteConstraints: RoadmapSiteConstraints[],
  ): BackendRoadmapSiteConstraints[] {
    return (siteConstraints || []).map((siteConstraint) => ({
      geoId: siteConstraint.geoId,
      minSize: YearlyValuesMapper.mapToBackend(siteConstraint.minSize),
      maxSize: YearlyValuesMapper.mapToBackend(siteConstraint.maxSize),
    }));
  }

  mapTargetsToBackend(targets: RoadmapTarget[]): BackendRoadmapTarget[] {
    return Coerce.toArray(targets).map((target) => ({ targetId: target.id }));
  }

  mapToFrontend(backendModel: BackendPortfolioRoadmap): PortfolioRoadmap {
    const coercedModel = Coerce.toObject(backendModel);
    const frontendStatus = this.mapStatusToFrontend(
      coercedModel.run,
      coercedModel.updatedAt,
    );

    return {
      clientId: coercedModel.clientId,
      id: coercedModel.roadmapId,
      roadmapId: coercedModel.roadmapId,
      campaigns: [],
      targets: this.mapTargetsToFrontend(coercedModel.targets),
      run: coercedModel.run
        ? this.mapRunMapToFrontend(coercedModel.run)
        : undefined,
      //TODO: Change after BE model
      runSettings: coercedModel.runSettings,
      createdAt: this.formatDate(coercedModel.createdAt),
      updatedBy: coercedModel.updatedBy,
      updatedAt: this.formatDate(coercedModel.updatedAt),
      status: frontendStatus,
      name: coercedModel.name,
      isBaseline: coercedModel.isBaseline,
      snapshot: coercedModel.run
        ? AnalysisMapper.mapToFrontend(coercedModel.run.snapshot)
        : undefined,
      canLaunchRoadmap: this.execStatus.canRoadmapBeComputed(frontendStatus),
      canDuplicateRoadmap:
        this.execStatus.canRoadmapBeDuplicated(frontendStatus),
      campaignsLoaded: false,
      campaignsLoading: false,
      timelineType: coercedModel.timelineType || 'gantt',
    };
  }

  mapTargetsToFrontend(targets: BackendRoadmapTarget[]): RoadmapTarget[] {
    return Coerce.toArray(targets).map((target) => ({ id: target.targetId }));
  }

  mapCampaignsToFrontend(
    campaigns: BackendRoadmapCampaign[],
    roadmapId: string,
  ): RoadmapCampaign[] {
    return Coerce.toArray(campaigns).map<RoadmapCampaign>((campaign) => ({
      startLimit: campaign.startDate,
      id: campaign.campaignId,
      endLimit: this.mapEndDateToFrontend(
        campaign.startDate,
        campaign.duration,
      ),
      type: REFERENCES[campaign.campaignType],
      rawType: campaign.campaignType,
      isShown: true,
      sites: this.mapSiteItemsToFrontend(campaign.sites),
      siteConstraints: this.mapSiteConstraintsToFrontend(
        campaign.siteConstraints,
      ),
      sitePercentConstraints: this.mapSiteConstraintsToFrontend(
        campaign.sitePercentConstraints,
      ),
      campaignConstraints: YearlyValuesMapper.mapToFrontend(
        Coerce.toArray(campaign.campaignConstraints),
      ),
      campaignPercentConstraints: YearlyValuesMapper.mapToFrontend(
        Coerce.toArray(campaign.campaignPercentConstraints),
      ),
      forcedInvestments: Coerce.toArray(campaign.forcedInvestments),
      owner: campaign.owner,
      roadmapId: roadmapId,
    }));
  }

  mapSiteItemsToFrontend(sites: BackendRoadmapSite[] = []): RoadmapSite[] {
    return sites.map((site) => ({
      startLimit: this.getYear(site.startDate),
      endLimit: this.mapEndDateToFrontend(site.startDate, site.duration),
      id: site.geoId,
    }));
  }

  mapSiteConstraintsToFrontend(
    siteConstraints: BackendRoadmapSiteConstraints[],
  ): RoadmapSiteConstraints[] {
    try {
      return (siteConstraints || []).map((siteConstraint) => ({
        geoId: siteConstraint.geoId,
        minSize: YearlyValuesMapper.mapToFrontend(siteConstraint.minSize),
        maxSize: YearlyValuesMapper.mapToFrontend(siteConstraint.maxSize),
      }));
    } catch (e) {
      return [];
    }
  }

  mapRunMapToFrontend(run: RoadmapRunMap): RoadmapRunMap {
    return {
      computationId: run.computationId,
      duration: run.duration,
      launchDate: run.launchDate,
      status: run.status,
      errors: run.errors,
      logFile: run.logFile,
      outputFile: run.outputFile,
      inputFile: run.inputFile,
      isExcelGenerated: !!run.isExcelGenerated,
    };
  }

  mapComputeMultipleToFrontend(backendModel: BackendRoadmapsRun): RoadmapsRun {
    return {
      roadmapIds: JSON.parse(backendModel.roadmaps),
      clientId: backendModel.clientId,
      updatedAt: this.formatDate(backendModel.updatedAt),
      updatedBy: backendModel.owner,
      run: backendModel.run
        ? this.mapRunMapToFrontend(backendModel.run)
        : undefined,
      status: this.mapStatusToFrontend(
        backendModel.run,
        backendModel.updatedAt,
      ),
    };
  }

  // UTILS
  mapStatusToFrontend(run: RoadmapRunMap, updatedAt: string): string {
    return Coerce.toObject(run).status
      ? this.execStatus.mapToFrontend(
          run.status,
          this.isComputationOutdated(run.launchDate, updatedAt),
        )
      : FE_EXECUTION_STATUS.DRAFT;
  }

  isComputationOutdated(launchDate, roadmapUpdatedDate): boolean {
    return (
      !!launchDate &&
      !!roadmapUpdatedDate &&
      new Date(roadmapUpdatedDate) > new Date(launchDate)
    );
  }

  formatDate(dateString: string) {
    return formatDate(dateString, 'dd MMM YYYY HH:mm', this.locale);
  }

  getYear(_date: string) {
    return _date ? new Date(_date).getFullYear().toString() : null;
  }

  mapEndDateToFrontend(_date: string, _duration: number = 0): string {
    if (_date) {
      const date = new Date(_date);
      const year = date.getFullYear() + _duration;
      return year.toString();
    }
  }

  // ENDPOINTS MAKER
  makeCreateEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.createRoadmap,
      clientId,
    );
  }

  makeUpdateEndpoint(clientId: string, id: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.updateRoadmap,
      clientId,
      id,
    );
  }

  makeGetEndpoint(clientId: string, id: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(baseUrl, endpoints.portfolio.roadmap, clientId, id);
  }

  makeGetAllEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(baseUrl, endpoints.portfolio.roadmaps, clientId);
  }

  makeComputeEndpoint(clientId: string, id: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.computeRoadmap,
      clientId,
      id,
    );
  }

  makeStopComputeEndpoint(clientId: string, id: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.stopComputeRoadmap,
      clientId,
      id,
    );
  }

  makeDeleteEndpoint(clientId: string, id: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.deleteRoadmap,
      clientId,
      id,
    );
  }

  makeComputeMultipleEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.computeMultipleRoadmap,
      clientId,
    );
  }

  makeDuplicateRoadmapsEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.duplicateRoadmaps,
      clientId,
    );
  }

  makeFetchRoadmapCampaignsEndpoint(
    clientId: string,
    roadmapId: string,
  ): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.fetchRoadmapCampaigns,
      clientId,
      roadmapId,
    );
  }

  makeUpdateRoadmapCampaignsEndpoint(
    clientId: string,
    roadmapId: string,
  ): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.updateRoadmapCampaigns,
      clientId,
      roadmapId,
    );
  }

  makeDeleteRoadmapCampaignsEndpoint(
    clientId: string,
    roadmapId: string,
  ): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.deleteRoadmapCampaigns,
      clientId,
      roadmapId,
    );
  }

  constructor(
    private httpClient: HttpClient,
    private config: ConfigService,
    private execStatus: ExecStatusChecker,
    @Inject(LOCALE_ID) private locale: string,
  ) {}
}
